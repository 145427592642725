<template>
  <div class="protocol">
    <div class="main">
      <div class="doing-what">用户协议</div>
      <div class="help-content">
        <div class="help-box">
          <div class="problem">
            <div class="p_content">
              <p class="p_title">用户协议</p>
              <p>本版更新时间：2024年05月24日</p>
              <p>
                本《用户服务协议》（以下称“本协议”）是用户（个人或单一实体，以下或称“您”）接受【上海域起网络科技有限公司】（包括但不限于其相关工作室，以下统称“公司”或“我们”）所提供的服务所使用的法律服务条款。公司在此特别提醒您认真阅读本协议的全部条款，特别是其中免除或者限制公司责任的免责声明条款（该等条款通常含有“不负任何责任”、“无义务”等词汇）以及用户须遵守的条款和其它限制用户权利的条款（该等条款通常含有“不得”等词汇），这些条款应在中国法律所允许的范围内最大程度地适用。除非用户接受本协议的全部条款，否则无权安装、复制、访问网站、充值、运行客户端软件或以其它方式使用公司提供的任何服务内容。 </p>
              <p><strong>一、特别提示</strong></p>
              <p>
                1、公司同意按照本协议的规定及其不时发布的操作规则提供基于互联网的相关服务(以下称“网络服务”)，为获得网络服务，您同意本协议的全部条款并按照页面上的提示完成全部的注册程序。您在进行注册程序过程中点击“同意”按钮即表示用户完全接受本协议项下的全部条款。这些条款可由公司根据相关政策进行更新修订，协议条款一旦发生变动，公司将会依法在相关页面上提示变更内容。修改后的协议一旦在页面上公布即有效代替原来的协议。您在使用公司提供的软件产品之前，应仔细阅读本协议，如不同意本服务协议及不时对其的修改，请停止使用公司提供的服务内容，如您点击同意并继续使用公司提供的服务内容，视为您已知悉并同意变更的内容。 </p>
              <p>
                2、特别申明，未成年人应在法定监护人的陪同下审阅和接受本协议。未成年人在使用公司的服务前，应事先取得父母（监护人）的同意。<strong>若您未取得监护人的同意，监护人可以通过联系公司官方公布的客服联系方式通知公司处理相关账号，公司有权对相关账号的功能、使用进行限制，包括但不限于浏览、发布信息、互动交流等功能。</strong>公司在游戏加载页面中对于游戏内容的适龄进行提示，父母（监护人）应注意查看，若父母（监护人）希望未成年人（尤其是十四周岁以下子女）得以使用本服务，应以法定监护人身份在法定未成年人游戏时间范围内合理设定孩子娱乐时间，培养孩子健康游戏习惯。未成年人用户应当在合理程度内使用公司提供的服务内容，不得因使用公司提供的服务内容而影响了日常的学习生活。公司将根据用户提交的实名注册信息判断是否需要将用户账号纳入相应的防沉迷系统，用户理解公司<strong>无义务也无能力</strong>对本款前述事项进行任何实质上的审查和确认。
              </p>
              <p>3、用户知悉并同意，出现以下情形之一的，公司有权将其游戏账号纳入相应的防沉迷系统，采取相应的防沉迷措施：</p>
              <p>（1）公司根据用户提交的实名注册信息判断用户未年满18周岁的；或</p>
              <p>（2）公司判断用户游戏行为符合未成年人游戏行为特征的；或</p>
              <p>（3）用户人脸识别验证未通过的；或</p>
              <p>（4）其他国家法律法规政策要求或公司有合理理由认为需要纳入防沉迷系统的情形的。</p>
              <p>
                另外，为了进一步提高实名认证的精准度，最大限度防止未成年人冒用他人身份信息，在相关政策及法律法规的要求下，公司根据实际需要可能在部分游戏或针对部分用户经用户同意后启用人脸识别验证，或者从第三方平台获取其游戏账号信息，并基于未成年人保护策略识别记录游戏行为，并判断游戏行为是否符合未成年人游戏行为特征。如域起游戏要求用户进行人脸识别验证，而其未通过的，公司也会将该游戏账号纳入相应的防沉迷系统，采取相应的防沉迷措施。您对上述人脸信息及第三方平台账号采集知晓并同意。</p>
              <p>
                对纳入相应防沉迷系统的游戏账号，公司有权依据国家有关法律法规及政策规定、本协议其他条款规定、游戏运营策略或根据法定监护人的合理要求采取以下一种或多种措施：</p>
              <p>
                （1）将与游戏相关的信息（包括但不限于游戏账号的登录信息、充值流水信息等）提供给用户的法定监护人，使得法定监护人可及时或同步了解游戏情况；</p>
              <p>（2）限制用户游戏账号的消费额度；</p>
              <p>（3）采取技术措施屏蔽某些游戏或游戏的某些功能；</p>
              <p>（4）限定用户游戏时间及游戏时长；</p>
              <p>（5）注销或删除游戏账号及游戏数据等相关信息；</p>
              <p>（6）用户法定监护人要求采取的，或公司认为可采取的其他合理措施，以限制或禁止其使用游戏；</p>
              <p>（7）国家法律法规或政策要求的相关措施。</p>
              <p>
                4、公司重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，并应在取得监护人的同意以及在监护人指导下正确使用公司游戏及相关服务。</p>
              <p>
                5、未成年人用户及其监护人理解并确认，如您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而可能导致的全部法律责任。</p>
              <p>6、公司游戏专门为未成年人提供青少年模式，监护人应为未成年人选择使用青少年模式。</p>
              <p>7、未成年人用户特别提示</p>
              <p>
                （1）未成年人使用公司游戏及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯；</p>
              <p>（2）青少年用户必须遵守《全国青少年网络文明公约》；</p>
              <p>（3）要善于网上学习，不浏览不良信息；</p>
              <p>（4）要诚实友好交流，不侮辱欺诈他人；</p>
              <p>（5）要增强自护意识，不随意约会网友；</p>
              <p>（6）要维护网络安全，不破坏网络秩序；</p>
              <p>（7）要有益身心健康，不沉溺虚拟时空。</p>
              <p>
                8、为更好地保护未成年人隐私权益，公司特别提醒您慎重发布包含未成年人素材的内容，一经发布，即视为您已获得权利人同意在游戏服务及相关服务展示未成年人的肖像、声音等信息，且允许公司依据本协议使用、处理该等与未成年人相关的内容。</p>
              <p>9、监护人特别提示</p>
              <p>
                （1）如您的被监护人使用公司游戏及相关服务的，您作为监护人应指导并监督被监护人的注册和使用行为，如您的被监护人申请注册游戏账号，公司将有权认为其已取得您的同意；</p>
              <p>
                （2）您的被监护人在使用公司游戏及相关服务时可能使用购买产品或服务等功能。您作为监护人，请保管好您的支付设备、支付账户及支付密码等，以避免被监护人在未取得您同意的情况下通过您的游戏账号使用购买等消费功能。</p>
              <p><strong>二、权属声明</strong></p>
              <p>
                1、公司定义的“服务内容”包括：文字、软件、声音、相片、录像、图表、版式结构；在广告中的全部内容；电子邮件的全部内容；公司游戏服务为客户提供的商业信息。所有这些内容均受著作权、商标权、专利权和其它财产所有权法律的保护。用户只有在获得公司或相关权利人的书面授权之后才能使用这些内容。</p>
              <p>
                2、游戏账号是公司按照本协议授权用户用于登录、使用游戏及相关服务的标识，其所有权属于公司，用户根据本协议仅享有游戏账号的使用权。用户不得擅自对账号进行出借、出租、买卖等行为，否则公司有权采取包括但不限于冻结账号、回收账号等措施。<strong>用户擅自进行上述违禁操作给任何第三方造成损失的，公司不承担任何责任。</strong>
              </p>
              <p>3、公司游戏服务的经营权属于公司所有，用户同意本服务仅供个人且仅于本服务平台范围内非商业性质的使用。用户同时承诺不经公司书面同意，<strong>不得利用本服务进行广告、销售、商业展示等商业性用途</strong>。<strong>用户擅自商业性使用本服务的，公司有权追究用户法律责任。 </strong>
              </p>
              <p>
                4、用户发表于公司游戏及配套网站的所有内容仅代表用户自己的立场和观点，与公司无关，<strong>由用户本人承担一切法律责任</strong>。
              </p>
              <p>
                5、用户承诺发表于公司游戏及配套网站的照片、文字著作权都属于用户本人所有，用户应保证该照片及文字无任何著作权属上的纠纷，<strong>因侵犯他人著作权或其他权利产生纠纷均由用户自行解决，给公司造成损失的，公司有权追究用户法律责任。</strong>
              </p>
              <p>
                6、用户同意将其上传的拥有著作权的照片、文字授予公司全球性、免许可费、非独家、可完全转授权及永久有效的使用权利，公司可以为了展示、散布及推广张贴前述内容之特定目的，将前述内容以使用、复制、修改、改编、出版、翻译、据以创作衍生作品后用于其它增值业务。 </p>
              <p><strong>三、用户的基本义务</strong></p>
              <p>
                1、用户承诺以其真实身份注册成为公司的用户，并保证所提供的个人身份资料信息真实、完整、有效，依据法律规定和必备条款约定对所提供的信息承担相应的法律责任。 </p>
              <p>
                2、用户充分理解并同意，公司会按照国家相关要求将用户的实名注册信息（包括相关实名认证、年龄情况及人脸信息等）运用于防沉迷系统之中，即公司可能会根据用户的实名注册信息判断其是否年满18周岁、提交的实名身份信息是否规范或实名验证是否通过等，从而决定是否对该用户的游戏账号予以防沉迷限制。</p>
              <p>
                3、用户有义务妥善保管在注册服务时获得的账号及密码谨防被盗或泄露，并为此账号及密码登入系统后的所有行为及活动负责。<strong>因密码被盗或泄露造成的全部责任和损失均由用户本人承担，公司对此概不负责。 </strong>
              </p>
              <p>4、若用户发现账号或密码遭他人非法使用或有异常使用的情形，应立即通知公司，并提交该账号为本人所有的有关证明，以便申请该账号的暂停登录和使用，公司因根据用户请求采取相应措施而造成用户及其他用户损失的，<strong>由该用户自行承担责任</strong>。
              </p>
              <p>
                5、公司根据用户的通知采取措施暂停用户账号的登录和使用的，公司应当要求用户提供并核实与其注册身份信息相一致的个人有效身份信息。用户没有提供其个人有效身份证件或者用户提供的个人有效身份证件与所注册的身份信息不一致的，公司有权拒绝用户的请求，<strong>因此造成损失的，由用户自行承担</strong>。
              </p>
              <p>6、用户不得使用他人身份信息或杜撰虚假证件注册。若用户存在上述行为，公司有权随时中断或终止向用户提供本协议项下的全部服务（包括收费服务）而<strong>无需对用户或任何第三方承担任何责任</strong>。
              </p>
              <p>7、用户同意若发现任何非法使用用户账号或利用安全漏洞的情况，应立即通告公司，且须承诺在申请账号时遵循如下规定：
                a、用户名和中文昵称的注册及使用须尊重网络道德，遵守中华人民共和国的有关法律法规。
                b、用户名和中文昵称中不得含有任何威胁、恐吓、漫骂、庸俗、亵渎、色情、淫秽、非法、反动、前后矛盾、攻击性、伤害性、骚扰性、诽谤性、辱骂性的或侵害他人知识产权的文字。不得涉及政治、国家领导人及近音国家领导人，以及任何可能引起法律纠纷的文字。
                公司发现用户使用此类昵称的，有权按照严重程度对该账号进行处罚，公司对情节严重的账号将进行永久封禁处理。</p>
              <p>8、用户不得将账号用于现实生活中的任何交易、转让。若用户将账号与他人于现实生活中进行交易或转让，由此产生纠纷的，<strong>公司概不负责</strong>。
              </p>
              <p>
                9、用户使用在公司注册的用户名在公司中的任何行为需要遵守公司其他各项服务条款，用户对登录后所持账号产生的行为依法享有权利和承担责任。 </p>
              <p>
                10、用户充分理解并同意，为高效利用服务器资源，如果用户3年内未使用游戏账号登录公司旗下游戏，公司有权在提前通知的情况下，对该账号及其账号下的游戏数据及相关信息采取删除等处置措施。（包括但不限于：清除该账号的积分、等级、游戏币等信息；删除该账号的注册信息、角色信息等一切相关信息），<strong>且不对因此所导致的任何损失负责</strong>。
              </p>
              <p>
                11、除用户自行填写的注册资料及用户充值的游戏币外，其它由公司视相关功能或统计或增强游戏娱乐性之需要而生成的游戏数据，其所有权由公司享有。 </p>
              <p>
                12、如果您选择进入游戏房间，视为您已知该等游戏的具体规则，并同意在该游戏房间中使用虚拟游戏币的相关规则。 </p>
              <p><strong>四、用户信息收集、使用及保护</strong></p>
              <p>
                1、用户同意并授权公司为向用户提供游戏及相关服务、提升服务品质等履行本协议之目的收集用户信息，这些信息包括实名注册信息、游戏账号下的游戏数据以及其他在使用游戏服务的过程中向公司提供或公司基于安全、用户体验优化等考虑而需自主收集的信息，收集个人信息的范围及目的详见《隐私政策》，公司对用户信息的收集将遵循本协议及相关法律的规定。</p>
              <p>
                2、用户知悉并同意，如国家有权机关依法要求公司协助调查游戏账号（包括实名身份信息）及游戏相关数据（如充值、消费、交易等数据）等信息的，公司可在通知用户后将前述相关信息提供给国家有权机关。</p>
              <p>
                3、用户应对通过游戏及相关服务了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息予以充分尊重，不应以收集、复制、存储、传播或以其他任何方式使用其他用户的个人信息，否则，由此产生的后果由用户自行承担。</p>
              <p>
                4、公司承诺不会修改、编辑或透露用户提供的信息。包括但不限于密码、姓名、地址、电话、身份证号码及保存在公司中的所有隐私信息（隐私信息种类详见《隐私政策》），除非有法律许可要求或公司基于诚实信用原则认为透露这些信息在以下四种情况是必要的： </p>
              <p>（1）遵守有关法律规定，遵从合法服务程序；</p>
              <p>（2）维护公司的商标所有权等合法权利权益；</p>
              <p>（3）在紧急情况下竭力维护用户个人和社会大众的隐私安全； </p>
              <p>（4）符合其他相关法律、法规及政策的要求。</p>
              <p>
                5、除本协议另有规定外，公司的游戏服务对用户信息收集、使用及保护等将遵循《隐私政策》、《未成年人隐私保护指引》等协议规定。除法律另有规定外，如用户同意或授权公司依据前述协议或规定收集、使用或处理涉及其个人信息后，需要撤回该同意或授权的，不影响公司在此之前已基于其同意或授权所进行的个人信息的收集、使用或处理等行为的效力。</p>
              <p><strong>五、游戏虚拟货币</strong></p>
              <p>
                1、公司以及公司旗下游戏中使用的虚拟货币包括但不限于金豆/钻石/元宝/神结晶/星钻，它是由公司发行，用户可以在游戏中免费获取，或使用法定货币按一定比例兑换购买，存在于游戏程序之外，以电磁记录方式存储于服务器内，并以特定数字单位表现的一种虚拟兑换工具。 </p>
              <p>
                2、公司仅仅发行游戏虚拟货币，不提供且禁止在游戏、网站或其他平台上以任何形式（包括玩家之间）交易游戏虚拟货币的业务。用户通过任何渠道进行虚拟货币交易行为给自己或第三方造成的一切损失，均由用户自己承担。用户发现存在其他玩家或渠道提供交易公司游戏虚拟货币服务的，通过【shichang@gg.com】联系我们进行举报。</p>
              <p>3、公司发行游戏虚拟货币的范围为中华人民共和国境内。 </p>
              <p>
                4、用户必须经公司公布的正规渠道购买游戏虚拟货币，若使用非公司所制作的产品进行充值，或以非法的方式进行充值，而造成用户权益受损时，不得因此要求公司做任何补偿或赔偿，公司也将保有随时终止其账号资格及使用各项服务并在平台内进行公示之权利。 </p>
              <p>5、在使用公司充值系统时，用户必须仔细确认自身的账号，若因为自身输入错误、操作不当或不了解充值计费方式等因素，造成充错账号、选错充值种类等情形而损及自身权益的，<strong>所有损失均应由用户自行承担</strong>。
              </p>
              <p>
                6、游戏虚拟货币的使用范围仅限于购买公司在游戏中所提供的虚拟服务，包括但不限于各种游戏道具，但不得用以支付、购买产品或兑换其它企业的任何产品和服务。 </p>
              <p>7、公司随时有权在合法的范围内提供更多的其它虚拟服务。 </p>
              <p>8、严禁用户利用游戏虚拟货币在游戏或网络或现实中进行任何非法活动，一经发现，公司有权进行惩处，没收其游戏虚拟货币、停止使用资格、永久删除用户账号<strong>并追究用户法律责任</strong>。
              </p>
              <p>
                9、公司以及公司旗下游戏及公司网站不提供任何游戏虚拟货币转账或交易系统，并禁止用户在游戏及公司网站等任何地方进行交易宣传或者表达含蓄的交易意向，严禁任何转让游戏虚拟货币的行为，一经发现，公司有权进行惩处，没收其游戏虚拟货币、停止使用资格甚至删除用户账号。 </p>
              <p>
                10、用户在站外以其它形式进行的游戏虚拟货币交易均不符合公司规定，所得任何权益不受公司保护，一经发现，立即对相关用户没收其游戏虚拟货币、停止使用资格并永久删除用户账号。 </p>
              <p>11、严禁用户利用非法手段或作弊手段获取游戏虚拟货币，例如非法注册大量小号，使用外挂软件挂机等，一经发现，公司有权进行惩处，没收其游戏虚拟货币、停止使用资格甚至删除用户账号，若用户在相关违规账号进行过充值，<strong>公司亦有权不退回相关费用。</strong>
              </p>
              <p>
                12、公司有权对用户游戏虚拟货币的使用情况进行监控，并在发现用户有违反本协议或其它管理规则及法律法规时，对用户游戏虚拟货币的使用进行必要的限制，并对涉嫌违规用户可以采取没收其游戏虚拟货币、停止使用资格甚至删除用户账号的处理措施。 </p>
              <p>
                13、公司以及公司旗下游戏中所使用的虚拟货币种类有金豆/钻石/元宝/神结晶/星钻，后续公司将根据实际情况新增或变更虚拟货币种类，具体的虚拟货币种类请以游戏中的描述为准。同时，除另有告知外，公司以及公司旗下游戏中所使用的所有虚拟货币均适用于本协议的约定。 </p>
              <p><strong>六、游戏数据的特别规定</strong></p>
              <p>1、公司严禁以下行为：</p>
              <p>（1）发布违法信息、严重违背社会公德以及其他违反法律禁止性规定的行为； </p>
              <p>（2）利用游戏作弊工具或者外挂、游戏BUG获取非法利益，严重侵犯公司利益的行为； </p>
              <p>（3）论坛、游戏中传播非法讯息、木马病毒、外挂软件等行为； </p>
              <p>
                （4）盗取游戏账号、游戏虚拟货币及兑奖券（偷盗游戏虚拟货币及兑奖券的行为指的是，在未经游戏虚拟货币及兑奖券持有人允许的情况下，将该游戏虚拟货币及兑奖券转移、使用、获得等行为或者利用系统漏洞获取游戏虚拟货币及兑奖券，并将该游戏虚拟货币及兑奖券转移、使用、获得等行为，公司有权根据记录清理以上方式获得的游戏虚拟货币及兑奖券）；</p>
              <p>
                （5）私下进行游戏账号、游戏虚拟货币、兑奖券、道具等交易，如查明涉嫌盗号，所涉及的账号将被系统回收，永久封停，永不启用；</p>
              <p>
                （6）私下利用游戏经营非法盈利性项目、涉嫌赌博获取非法利益、未通过正规渠道进行充值、恶意扰乱游戏正常秩序和聚众侵占游戏资源的账号一旦查实也将被系统回收，永久封停，永不启用；</p>
              <p>（7）实施违反本协议和相关规定、管理办法、公告、重要提示，对公司和其他用户利益造成严重损害的其他行为；</p>
              <p>
                （8）因游戏中举办的活动结束，相关道具被删除或不再提供兑换，用户不得提出任何赔偿要求，公司对于活动有最终的解释权；</p>
              <p>
                （9）用户连续三年没有上线游戏，则自满三年的当天的24时起，公司有权在提前通知的情况下采取措施删除该用户账号在游戏数据库中的任何记录（包括但不限于注册信息，角色信息，等级物品信息等）。 </p>
              <p>
                2、对于违反以上规定的用户，一经发现，公司将视情节轻重对其采取删除其发表的文章、没收其游戏虚拟货币、兑奖券财富、封停其游戏账号、删除其游戏账号乃至屏蔽其计算机的处罚。 </p>
              <p>3、如果公司发现用户账号数据异常，有权采取相应措施，包括对该异常账号的冻结、终止，该异常账号内游戏虚拟货币、兑奖券等相关数据的清除，<strong>用户不得因此要求公司做任何补偿</strong>。
              </p>
              <p>4、账号因多人使用而引发的账号归属纠纷，公司概不处理，视情节严重，公司有权回收账号，永久封停，永不启用。 </p>
              <p>
                5、用户需要注销游戏账号的，可按照该游戏官方提供的账号注销指引进行操作，并应保证满足游戏官方及《隐私政策》中公布的有关游戏账号注销的相关条件，同时同意游戏官方及《隐私政策》中公布的其他有关注销的规则。如用户选择注销游戏账号的，该游戏账号下的相关游戏收益也将会被清除。如届时该游戏账号下还存在游戏收益的（包括在本游戏使用期间已产生的及未来可能产生的游戏收益），公司有权对该游戏账号下的全部游戏收益做清除处理，<strong>因此产生的后果由用户自行承担</strong>。
              </p>
              <p><strong>七、服务的停止和更改</strong></p>
              <p>1、发生下列情形之一时，公司有权停止或中断向用户提供的服务： </p>
              <p>（1）、用户有发布违法信息、严重违背社会公德、以及其他违反法律禁止性规定的行为； </p>
              <p>（2）、用户提供虚假注册身份信息，或实施违反本服务条款的行为； </p>
              <p>（3）、游戏中合伙作弊，尚未对其他用户利益造成严重影响的行为； </p>
              <p>
                （4）、发布不道德信息、广告、言论、辱骂骚扰他人，扰乱正常的网络秩序和游戏秩序的行为，以及实施违反本协议和相关规定、管理办法、公告、重要提示，对公司和其他用户利益造成损害的其他行为； </p>
              <p>（5）、对于网络设备进行必要的保养及施工； </p>
              <p>（6）、发生突发性的网络设备故障时；</p>
              <p>（7）、由于不可抗力因素致使公司无法提供线上服务； </p>
              <p>（8）、由于相关政府机构的要求。</p>
              <p>2、在本服务条款约定的情形下，公司就停止或更改或终止向用户所提供的服务而可能产生的不便或损害，<strong>公司对用户本人或任何第三人均不负任何损害赔偿责任</strong>。
              </p>
              <p>
                3、用户应了解并同意，公司所提供的服务可能因公司本身、其它合作厂商或相关电信业者网络系统软硬件设备的故障、失灵或因合作方及相关电信工作人员人为操作的疏失而全部或一部分中断、暂时无法使用、迟延或因他人侵入公司系统篡改或伪造变造资料等，造成服务的停止或中断或用户档案缺失，<strong>用户不得要求公司提供任何的补偿或赔偿</strong>。
              </p>
              <p>4、公司根据本服务条款取消或停止用户的资格或加以限制，<strong>用户不得要求任何补偿或赔偿</strong>。 </p>
              <p>
                5、公司有权仅根据其判断，单方决定新增、修改、删除、暂停或终止其所提供的全部或部分服务（包括且不限于增加、暂停或终止某个游戏的运营），用户不得因此要求任何补偿或赔偿。公司在终止某个游戏的运营前会提前30日向用户发出公告。 </p>
              <p>6、公司对其服务不保证不出现任何程序BUG，<strong>并对由此可能产生的问题不承担任何赔偿责任</strong>。 </p>
              <p><strong>八、用户违规处罚办法</strong></p>
              <p>
                用户违规行为指的是用户违反公司用户服务条款、公司论坛服务条款、公司作弊处罚条款等公司任何服务条款或管理制度的行为，对于用户违规行为的处罚办法如下： </p>
              <p>1、用户如有违反公司用户服务条款的行为，公司有权视情节轻重对其采取删除其发表的文章、没收其游戏虚拟货币及虚拟道具、封禁其游戏账号、永久删除其游戏账号的处罚，<strong>用户不得因此要求本公司做任何补偿或退费</strong>。
              </p>
              <p>2、用户如有不正当游戏行为，将被依据<span class="p_link" @click="handleClick('/gamesecurity.html')">《游戏安全处罚规则》</span>的规定予以相应的处罚。 </p>
              <p>
                3、用户如有违反公司其他服务条款和管理制度的行为，将被依据相关规定予以相应的处罚。公司各服务条款或管理制度之间不相抵，可各自执行。 </p>
              <p>
                4、未经公司书面同意，用户以任何营利性、非营利性或损害公司利益的目的实施以下几种行为的，公司保留追究上述未经许可行为一切法律责任的权利，给公司造成经济或名誉上损失的，公司有权根据相关法律法规另行要求赔偿，情节严重涉嫌犯罪的，公司将提交司法机关追究刑事责任： </p>
              <p>（1）进行编译、反编译等方式破解公司游戏软件作品的行为； </p>
              <p>（2）利用技术手段破坏软件系统或者服务器的行为； </p>
              <p>（3）利用游戏外挂、作弊软件、系统漏洞侵犯公司利益的行为； </p>
              <p>（4）对游戏进行截屏、录像或利用游戏中数据、图片、截屏进行发表的行为； </p>
              <p>（5）制作游戏线下衍生品的行为；</p>
              <p>（6）利用公司提供的服务内容进行赌博等违法犯罪的行为； </p>
              <p>（7）其他严重侵犯公司知识产权的行为。 </p>
              <p><strong>九、服务条款的修改</strong></p>
              <p>
                公司保留随时修改本服务条款的权利，修改本服务条款时，公司将根据相关法律法规于相关网站公告修改的事实，届时需要用户重新查看用户协议并进行确认。若用户不同意修改的内容，可停止使用公司的服务。若用户点击同意用户协议并继续使用公司的服务，即视为用户已接受公司所修订的内容。 </p>
              <p><strong>十、法律适用和争议解决</strong></p>
              <p>1、本协议签订地为中华人民共和国上海市浦东新区。</p>
              <p>2、本协议的订立、效力、解释、履行和争议的解决均适用中华人民共和国大陆地区法律。</p>
              <p>
                3、因本协议所产生的以及因履行本协议而产生的任何争议，双方均应本着友好协商的原则加以解决；若协商不一致，用户同意将纠纷或争议提交至上海市浦东新区人民法院管辖。</p>
              <p>4、本协议条款无论因何种原因部分无效，其余条款仍有效，对各方具有约束力。</p>
              <p><strong>*特别声明</strong></p>
              <p>
                鉴于：我国《民法典》第496条规定：采用格式条款订立合同的，提供格式条款的一方应当遵循公平原则确定当事人之间的权利和义务，并采取合理的方式提示对方注意免除或者减轻其责任等与对方有重大利害关系的条款，按照对方的要求，对该条款予以说明。公司在此依法做出特别声明如下：公司采取合理的方式提请用户注意的义务将通过如下方式实现：在本合同中公司以明确的足以引起用户注意的加粗字体、颜色标记等合理方式提醒用户注意相关条款（需要强调的是，还包括用户应特别注意任何未明确标记的含有“不承担”、“免责”“不得”等形式用语的条款），该等条款的确认将导致用户在特定情况下的被动、不便、损失，包括但不限于本合同第三部分第5条、第6条、第8条等，请用户在确认同意本合同之前再次阅读上述条款。双方确认上述条款非属于《民法典》第497条规定的“不合理地免除或者减轻其责任、加重对方责任、限制对方主要权利”的条款，公司尊重用户的权利尤其是诉讼的权利，但作为全球运营的公司，而用户选择同意合同并使用游戏即视为双方对此约定达成了一致意见。用户如有任何需要说明条款的要求，请立即停止使用公司提供的服务，同时请发送邮件至【shichang@gg.com】联系我们，若用户未致电或发送有说明条款要求的邮件至公司而选择同意该协议，则双方在此确认公司已依法履行了根据用户要求对相关条款进行说明的法定义务，公司已给予用户充足的时间与充分的选择权来决定是否缔结本合同。 </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default ({
  name: "Protocol",
  data() {
    return {
      showTitle: false
    }
  },
  methods: {
    handleClick(path) {
      this.$router.push(path)
    }
  }
})
</script>

<style lang="scss" scoped>
body {
  background-color: #fff;
}

table {
  border-collapse: collapse;
}

tr,
td {
  border: 1px solid #555 !important;
  padding: .2*2rem;
  margin: 0.1*2rem
}

a {
  color: #3a79d4;
}

.doing-what {
  border-left: 0.125*2rem solid #3a79d4;
  font-size: 0.6875*2rem;
  padding-left: 0.6125*2rem;
  line-height: 0.6875*2rem;
  margin: 0.625*2rem 10.3125*2rem;
}

.help-content {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;

  .help-box {
    margin: 1.25*2rem 0;
    overflow: hidden;
    width: 35.625*2rem;
    background-color: #fff;
    padding: 0.8125*2rem;
    box-sizing: border-box;
    border-radius: 0.1875*2rem;
    border: 0.0625*2rem solid #eee;

    .problem {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      background-color: #f5f5f5;
      border-radius: 0.1875*2rem;
      font-size: 0.5625*2rem;
      box-sizing: border-box;
      padding: 0 1.25*2rem 0.625*2rem 1.25*2rem;

      .p_content {

        p {
          text-indent: 2em;
          line-height: 1.2*2rem;
          font-size: 0.4375*2rem;
        }

        .p_title {
          font-size: 0.5587*2rem;
          font-weight: 700;
          line-height: 1.5*2rem;
          text-align: center;
        }

        .p_title1 {
          font-size: 0.5587*2rem;
          font-weight: 700;
          line-height: 1.5*2rem;
          text-indent: 0;
        }

        .p_strong {
          font-weight: bold;
        }

        .p_link {
          color: #3a79d4;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
